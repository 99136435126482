import { useSelector } from "react-redux"

import { setCurrentNoteId } from "features/noteSlice"

import { captureException } from "@sentry/nextjs"

import { selectBlog, getBlogByUrl } from "features/blogSlice"

import { wrapper } from "store"

import Head from "components/Head"
import { isValidBlog } from "@/util/format"

import { GetServerSideProps } from "next"
import { useRouter } from "next/router"
import SubscribeModal from "components/SubscribeModal/Modal"
import useSubscribeModalState from "hooks/useSubscribeModalState"
import PublicLayout from "components/layouts/PublicLayout"
import { ReactElement } from "react"

export const getServerSideProps: GetServerSideProps =
  wrapper.getServerSideProps((store) => async (ctx) => {
    try {
      const { params } = ctx

      const blogName = params?.blogname as string

      const promises = []
      if (isValidBlog(blogName)) {
        console.log("Valid blog name!")
        promises.push(store.dispatch(getBlogByUrl(blogName)))
      } else {
        console.log("Invalid blog name!")

        return {
          notFound: true,
        }
      }

      // Need to reset this. If the user is publicly browsing a blog,
      // every time they go back to the homepage it will incorrectly show
      // the last note they read.
      promises.push(store.dispatch(setCurrentNoteId(null)))

      console.log(" Awaiting all promises...")

      await Promise.all(promises)

      console.log(" Awaited all promises!")

      console.log("Here is the store blog ID", store.getState()?.blog?.blog?.id)
      let notFound = false
      if (!store.getState()?.blog?.blog?.id) {
        console.log("Returning not found, 404!")
        notFound = true
      }

      return {
        props: {},
        notFound,
      }
    } catch (error) {
      console.log("Error in getServerSideProps", error)
      captureException(error)
      return {
        notFound: true,
      }
    }
  })

export default function BlogSubscribeComponent() {
  const blog = useSelector(selectBlog)
  const router = useRouter()

  // Get any pre-filled email fields from the URL.
  const emailPrefilledEncoded = router.query.email as string | undefined
  const emailPrefilled =
    emailPrefilledEncoded && decodeURIComponent(emailPrefilledEncoded)
  // The sourceEmbed query param is used to indicate that the user is coming from an embedded
  // subscribe page by filling in their email and clicking the "subscribe" button, so we should
  // auto-subscribe them.
  const sourceEmbed = router.query.source_embed as boolean | undefined

  // This is necessary to call here, even if we don't directly use it, or the SubscribeModal
  // states won't work correctly and they will skip communities.
  useSubscribeModalState()

  return (
    <div>
      <Head blog={blog} title={`${blog.name}`} />

      <SubscribeModal
        initialPopup={false}
        open={true}
        setOpen={() => {
          console.log("Setting open to false!")

          router.push(`/${blog.url}`)
        }}
        doNotSubscribeImmediately={!sourceEmbed}
        emailPrefilled={emailPrefilled}
      />
    </div>
  )
}

BlogSubscribeComponent.getLayout = (page: ReactElement) => {
  return <PublicLayout>{page} </PublicLayout>
}
